import { config } from '@/config';

export function getLogger(context: Function | string) {
  return new AppLogger(context);
}

/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
class AppLogger implements Logger {
  private readonly displayLogLevel = config.logging.displayLogLevel;
  private name = '';
  private dateFormat: Intl.DateTimeFormat = new Intl.DateTimeFormat('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    hour12: false,
  });

  constructor(...context: Array<string | Function>) {
    this.name = context
      .filter(c => c !== '')
      .map(c => {
        if (typeof c === 'function') {
          return c.name;
        } else {
          return c;
        }
      })
      .join('#');
  }

  error(
    context: string | Function,
    error: string | Error,
    data?: LogData
  ): void {
    this.log(
      'error',
      typeof context === 'function' ? `#${context.name}` : context,
      { error, ...data }
    );
  }

  warn(
    context: string | Function,
    error: string | Error,
    data?: LogData
  ): void {
    this.log(
      'warn',
      typeof context === 'function' ? `#${context.name}` : context,
      { error, ...data }
    );
  }

  info(msg: string, data?: LogData): void {
    this.log('info', msg, data);
  }

  debug(msg: string, data?: LogData): void {
    this.log('debug', msg, data);
  }

  trace(msg: string, data?: LogData): void {
    this.log('trace', msg, data);
  }

  inspect(context: Function, data: LogData): void {
    if (config.logging.level === 'trace') {
      this.log('trace', `#${context.name}`, data);
    }
  }

  for(...context: Array<string | Function>): Logger {
    return new AppLogger(this.name, ...context);
  }

  private log(levelName: string, message: string, data?: LogData): void {
    try {
      const maxLogLevel = this.getLogLevel(config.logging.level.toLowerCase());
      const logLevel = this.getLogLevel(levelName);
      let logMethod: Function;

      switch (levelName) {
        case 'error':
          logMethod = console.error;
          break;
        case 'warn':
          logMethod = console.warn;
          break;
        case 'info':
          logMethod = console.info;
          break;
        case 'debug':
        case 'trace':
          logMethod = console.debug;
          break;
        default:
          logMethod = console.log;
      }

      if (logLevel <= maxLogLevel) {
        const prefix = `${(this.displayLogLevel
          ? `${this.timestamp()} ${levelName.toUpperCase()} - `
          : `${this.timestamp()} `) + this.name}::`;
        const logArgs: unknown[] = [
          `%c${prefix}`,
          `color: ${this.getColor(levelName)};`,
          message,
        ];

        if (data) {
          logArgs.push(data);
        }

        logMethod(...logArgs);
      }
    } catch (e) {
      console.warn(e);
    }
  }

  private getLogLevel(levelName: string): number {
    return ['error', 'warn', 'info', 'debug', 'trace', 'off'].indexOf(
      levelName
    );
  }

  private getColor(level: string) {
    switch (level) {
      case 'error':
        return 'red';
      case 'warn':
        return 'orange';
      case 'info':
        return 'limegreen';
      case 'debug':
        return 'darkturquoise';
      case 'trace':
        return 'dodgerblue';
      default:
        return 'gray';
    }
  }

  private timestamp(): string {
    return this.dateFormat.format(new Date());
  }
}
