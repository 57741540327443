import { BehaviorSubject, Observable } from 'rxjs';
import { getLogger } from '../core/logging';
import { config } from '@/config';

export class ImageAnalyzer {
  private readonly log = getLogger(ImageAnalyzer);
  private readonly defaultColor = config.theme.defaultColor;

  constructor(private canvas: HTMLCanvasElement) {}

  observeDominantColor(img: HTMLImageElement): Observable<string> {
    if (img.crossOrigin !== 'anonymous') {
      throw new Error(
        'Source image `crossOrigin` property must be set to "anonymous".'
      );
    }

    const colorSubject = new BehaviorSubject(this.calculateDominantColor(img));

    img.addEventListener('load', () => {
      this.log.inspect(this.observeDominantColor, img);
      colorSubject.next(this.calculateDominantColor(img));
    });
    return colorSubject;
  }

  private calculateDominantColor(img: HTMLImageElement): string {
    const context = this.canvas.getContext('2d');
    const width = (this.canvas.width =
      img.naturalWidth || img.offsetWidth || img.width);
    const height = (this.canvas.height =
      img.naturalHeight || img.offsetHeight || img.height);
    const sampleInterval = 16;
    const colorCount: Record<string, number> = {};

    if (context && width > 0 && height > 0) {
      context.drawImage(img, 0, 0);

      try {
        const data = context.getImageData(0, 0, width, height);
        const length = data.data.length;
        let i = 0;

        this.log.inspect(this.calculateDominantColor, data);

        while (i < length) {
          const r = data.data[i];
          const g = data.data[i + 1];
          const b = data.data[i + 2];
          const a = data.data[i + 3];

          if (this.isValidThemeColor(r, g, b, a)) {
            const hexString = this.rgbToHexString(r, g, b);

            colorCount[hexString] = (colorCount[hexString] || 0) + 1;
          }

          i += sampleInterval * 4;
        }

        // eslint-disable-next-line no-console
        return this.selectModalColor(colorCount);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
        return this.defaultColor;
      }
    } else {
      return this.defaultColor;
    }
  }

  private selectModalColor(colorCount: Record<string, number>) {
    let maxCount = 0;
    let modalColor = this.defaultColor;

    Object.keys(colorCount).forEach(color => {
      if (colorCount[color] > maxCount) {
        maxCount = colorCount[color];
        modalColor = color;
      }
    });

    return modalColor;
  }

  private isValidThemeColor(r: number, g: number, b: number, a: number) {
    const max = Math.max(r, g, b) / 255;
    const min = Math.min(r, g, b) / 255;
    const l = (max + min) / 2;

    if (
      a === 255 &&
      l <= config.theme.lightnessCeiling &&
      l >= config.theme.lightnessFloor
    ) {
      const d = max - min;
      const s = l > 0.5 ? d / (2 - d) : d / (max + min);

      return s > config.theme.saturationFloor;
    } else {
      return false;
    }
  }

  private rgbToHexString(r: number, g: number, b: number): string {
    return `#${this.componentToHexString(r)}${this.componentToHexString(
      g
    )}${this.componentToHexString(b)}`;
  }

  private componentToHexString(c: number): string {
    const hexDigit = Math.floor(c / 16).toString(16);
    return hexDigit + hexDigit;
  }
}
