import { Observable } from 'rxjs';
import { config } from '@/config';
import { getLogger } from '@/core/logging';
import { AsyncGate } from '@/core/utils';

export class AudioController {
  get currentTime(): number {
    return this.element.currentTime;
  }

  set currentTime(time: number) {
    this.element.currentTime = time;
  }

  get duration(): number {
    return this.element.duration || 0;
  }

  get loading$(): Observable<boolean> {
    return this.loadingGate.locked$;
  }

  get loop(): boolean {
    return this.element.loop;
  }

  set loop(loop: boolean) {
    this.element.loop = loop;
  }

  get muted(): boolean {
    return this.element.muted;
  }

  get paused(): boolean {
    return this.element.paused;
  }

  get volume(): number {
    return this.element.volume;
  }

  set volume(volume: number) {
    this.element.volume = volume;
  }

  get rate(): number {
    return this.element.playbackRate;
  }

  set rate(rate: number) {
    this.element.playbackRate = rate;
  }

  private readonly log = getLogger(AudioController);
  /* eslint-disable no-empty */
  private callbacks: {
    start(): void;
    end(): void;
    bufferProgress(portion: number): void;
  } = {
    start: () => {},
    end: () => {},
    bufferProgress: () => {},
  };
  /* eslint-enable no-empty */
  private loadingGate = new AsyncGate();
  private buffered = 0;
  private newAudio = false;

  constructor(private element: HTMLAudioElement) {
    this.setupHtml5AudioEventListeners();
  }

  /**
   * sets callback functions for audio start, audio end, and buffer progress events
   *
   * @param callbacks object of optional callback functions
   */
  setCallbacks(callbacks: {
    start?(): void;
    end?(): void;
    bufferProgress?(portion: number): void;
  }) {
    this.callbacks = {
      ...this.callbacks,
      ...callbacks,
    };
  }

  /**
   * prepares the audio for a story
   *
   * @param src audio source
   */
  setSource(src: string, loadAudio = false): void {
    this.log.debug('setSource', { src, loadAudio });
    this.element.pause();
    this.element.src = src;
    this.buffered = 0;
    this.newAudio = true;

    if (config.preloadAudio || loadAudio) {
      const intervalId = setInterval(() => {
        const buffer = this.calculateBufferProgress();

        if (buffer < this.currentTime / this.duration) {
          this.loadingGate.lock();
        } else {
          this.loadingGate.unlock();
        }

        if (buffer === 1) {
          clearInterval(intervalId);
        }
      }, 1000);

      this.element.load();
    }
  }

  async play(): Promise<void> {
    this.log.debug('play');
    this.loadingGate.lock();

    try {
      await this.element.play();
    } catch {
      this.loadingGate.unlock();
      return Promise.reject();
    }

    if (this.newAudio) {
      this.callbacks.start();
      this.newAudio = false;
    }

    this.loadingGate.unlock();
  }

  pause() {
    this.log.debug('pause');
    this.element.pause();
  }

  mute() {
    this.element.muted = true;
  }

  unmute() {
    this.element.muted = false;
  }

  /**
   * moves the current time to n
   *
   * @param n the time in seconds
   */
  seek(n: number) {
    this.element.currentTime = n;
  }

  /**
   * moves the current time to n times the total duration of the audio
   *
   * @param p a value between 0 and 1
   */
  seekProportionately(p: number) {
    this.seek(p * this.duration);
  }

  /**
   * moves playback to the current time plus `seconds`
   *
   * @param n a value between 0 and 1
   */
  seekRelative(seconds: number) {
    this.seek(Math.max(0, Math.min(this.currentTime + seconds, this.duration)));
  }

  /**
   * @see https://developer.mozilla.org/en-US/Apps/Fundamentals/Audio_and_video_delivery/buffering_seeking_time_ranges#Creating_our_own_Buffering_Feedback
   */
  private setupHtml5AudioEventListeners() {
    this.element.addEventListener('waiting', () => this.loadingGate.lock());
    this.element.addEventListener('stalled', () => this.loadingGate.lock());
    this.element.addEventListener('playing', () => this.loadingGate.unlock());
    this.element.addEventListener('ended', () => this.callbacks.end());
    this.element.addEventListener('progress', () => {
      this.calculateBufferProgress();
    });
  }

  private calculateBufferProgress(): number {
    const e = this.element;
    const duration = e.duration;

    if (duration > 0) {
      for (let i = e.buffered.length - 1; i >= 0; i--) {
        if (e.buffered.start(i) <= e.currentTime) {
          const buffered = e.buffered.end(i) / e.duration;

          if (buffered !== this.buffered) {
            this.buffered = buffered;
            this.callbacks.bufferProgress(buffered);
          }

          return buffered;
        }
      }
    }

    return 0;
  }
}
