import { Observable } from 'rxjs';
import { config } from '@/config';
import { getLogger } from '@/core/logging';
import { AsyncGate, fetchJson } from '@/core/utils';

export class PlaylistLoader {
  get loading$(): Observable<boolean> {
    return this.loadingGate.locked$;
  }

  get trackList(): PlaylistTrackInfo[] {
    return this.slots.map(slot => {
      const publisher = this.publishers[slot.story.publisherId];

      return {
        story: {
          id: slot.story.id,
          title: slot.story.content.title,
          duration: slot.story.formats.mp3.duration,
        },
        channel: {
          id: publisher.id,
          name: publisher.channelName || publisher.name,
        },
      };
    });
  }

  set query(query: PlaylistQuery) {
    this._query = { ...query, _v: config.version };
    this.slots = [];
    this.slotCount = 0;
    this.pageSize = 0;
  }

  private readonly log = getLogger(PlaylistLoader);
  private readonly loadingGate = new AsyncGate();
  private readonly publishers: Record<string, Publisher> = {};
  private _query: PlaylistQuery = {};
  private slots: PlaylistSlot[] = [];
  private slotCount = 0; // unknown count
  private pageSize = 0; // unknown page size

  getPublisher(id: string): Publisher {
    return this.publishers[id];
  }

  async hasNext(i: number): Promise<boolean> {
    return this.slotCount
      ? i + 1 < this.slotCount
      : !!(await this.loadSlot(i + 1));
  }

  async loadSlot(i: number): Promise<PlaylistSlot | undefined> {
    if (!this.slots[i]) {
      await this.loadPage(this.pageSize ? Math.floor(i / this.pageSize) : 0);
    }

    return this.slots[i];
  }

  private async loadPage(pageIndex: number): Promise<void> {
    this.loadingGate.lock();

    try {
      const query: Record<string, string | undefined> = {
        ...this._query,
        p: pageIndex ? `${pageIndex + 1}` : undefined,
        n: `${config.page.pageSize}`,
      };
      const response = await fetchJson<ResponseEnvelope<Playlist>>(
        this.log,
        'playlist',
        query
      );
      const result = response.result;

      if (result) {
        if (config.enablePagination && response.meta) {
          this.slotCount = response.meta.count || 0;
          this.pageSize = response.meta.pageSize || result.slots.length;
        } else {
          this.slotCount = result.slots.length;
          this.pageSize = this.slotCount;
        }

        Object.values(result.publishers).forEach(publisher => {
          const thumbSrc = publisher.display.thumbnail.default;

          publisher.display.thumbnail.small = this.transformThumbnail(
            thumbSrc,
            256
          );
          publisher.display.thumbnail.large = this.transformThumbnail(
            thumbSrc,
            512
          );
          this.publishers[publisher.id] = publisher;
        });

        result.slots.forEach((slot, i) => {
          const thumbSrc = slot.story.display.thumbnail.default;

          slot.story.display.thumbnail.small = this.transformThumbnail(
            thumbSrc,
            256
          );
          slot.story.display.thumbnail.large = this.transformThumbnail(
            thumbSrc,
            512
          );
          this.slots[i + this.pageSize * pageIndex] = slot;
        });
      } else {
        this.log.trace('failed to load playlist', response);

        if (response.error) {
          throw new Error(
            typeof response.error === 'string'
              ? response.error
              : response.error.message
          );
        } else {
          throw new Error('Failed to load playlist');
        }
      }
    } finally {
      this.loadingGate.unlock();
    }
  }

  private transformThumbnail(src: string, width: number) {
    return src.replace(
      '/image/upload/d_SpokenLayer_Logo_Verticle_fb9a1b.png',
      `/image/upload/ar_1:1,c_fill/c_scale,w_${width},dpr_auto,f_auto,q_auto/d_SpokenLayer_Logo_Verticle_fb9a1b.png`
    );
  }
}
