const defaultsDeep = require('lodash.defaultsdeep');

const mode = process.env.NODE_ENV;
const env = process.env.VUE_APP_ENV || 'local';
const defaultConfig = {
  version: 'alpha',
  env: '',
  apiUrl: 'https://api.spokenlayer.net/web-player',
  disableAds: false,
  timerTickMs: 100,
  enablePagination: true,
  preloadAudio: false,
  logging: {
    level: 'info',
    displayLogLevel: false,
    logPlayerJsEvents: false,
  },
  msStallAfterAd: 1000,
  gtm: {
    id: 'GTM-KZTSWK2',
    queryParams: {
      /* eslint-disable @typescript-eslint/camelcase */
      gtm_auth: 'O7RtDepwhyP6pDHgpTUuJg',
      gtm_preview: 'env-3',
      gtm_cookies_win: 'x',
      /* eslint-enable @typescript-eslint/camelcase */
    },
  },
  og: {
    appId: '806647496824905',
  },
  aria: {
    labels: {
      play: 'Play, Alt-K',
      playMac: 'Play, Option-K',
      pause: 'Pause, Alt-K',
      pauseMac: 'Pause, Option-K',
      prev: 'Previous Episode, Alt-P',
      prevMac: 'Previous Episode, Option-P',
      next: 'Next Episode, Alt-N',
      nextMac: 'Next Episode, Option-N',
      replay: 'Replay Episode, Alt-P',
      replayMac: 'Replay Episode, Option-P',
      'back-30': 'Rewind 30 seconds',
      'forward-30': 'Fast-forward 30 seconds',
      slLink: 'Go to SpokenLayer homepage',
      share: 'Share this episode',
      help:
        'SpokenLayer Web Player. For a list of keyboard shortcuts, press Alt-H.',
      helpMac:
        'SpokenLayer Web Player. For a list of keyboard shortcuts, press Option-H',
    } as Record<string, string>,
  },
  text: {
    sponsored: 'Content will resume after this sponsored message.',
  },
  theme: {
    defaultColor: '#00bfee',
    saturationFloor: 0.25,
    lightnessFloor: 0.3,
    lightnessCeiling: 0.7,
  },
  page: {
    pageSize: 9,
    breakSize: 4,
  },
  breakpoint: {
    horizontal: 1200,
    vertical: 800,
  },
  reverbHostnames: [
    'play.dev.spokenlayer.net',
    'play.staging.spokenlayer.net',
    'play.spokenlayer.net',
    'play.spokenlayer.com',
  ],
};
const envOverrides: Record<string, DeepPartial<typeof defaultConfig>> = {
  prod: {
    gtm: {
      queryParams: {
        /* eslint-disable @typescript-eslint/camelcase */
        gtm_auth: '1Bfm5sEovcRDyYuLLZmZCg',
        gtm_preview: 'env-1',
        /* eslint-enable @typescript-eslint/camelcase */
      },
    },
    og: {
      appId: '801593273951905',
    },
  },
  staging: {
    apiUrl: 'https://api.staging.spokenlayer.net/web-player',
    logging: { level: 'debug' },
  },
  dev: {
    apiUrl: 'https://api.dev.spokenlayer.net/web-player',
    logging: { level: 'trace', logPlayerJsEvents: true },
  },
  local: {
    // apiUrl: 'http://localhost:3333/web-player',
    apiUrl: 'https://api.spokenlayer.net/web-player',
    logging: { level: 'trace', logPlayerJsEvents: true },
  },
};
const envVariableOverrides = {};

function setProperty(
  target: Configurable,
  path: string[],
  value: string
): void {
  let o: Configurable = target;

  path.forEach((prop, i, props) => {
    if (i < props.length - 1) {
      o = (o[prop] as Configurable) || (o[prop] = {});
    } else {
      o[prop] = castValue(value);
    }
  });
}

function castValue(value: string): string | number | boolean {
  if (/^-?[0-9.]+$/.test(value)) {
    return +value;
  } else if (value.toLowerCase() === 'true') {
    return true;
  } else if (value.toLowerCase() === 'false') {
    return false;
  }

  return value;
}

for (const p of Object.keys(process.env)) {
  if (p.startsWith('VUE_APP_CONFIG_')) {
    setProperty(
      envVariableOverrides,
      p.substring('VUE_APP_CONFIG_'.length).split('_'),
      process.env[p]
    );
  }
}

export const config: typeof defaultConfig = defaultsDeep(
  {
    mode,
    env,
  },
  envVariableOverrides,
  envOverrides[env],
  defaultConfig
);
