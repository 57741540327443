import Vue, { VueConstructor } from 'vue';
import VueMeta from 'vue-meta';
import * as balanceText from 'vue-balance-text';
import { config } from './config';
import { getLogger } from './core/logging';
import { getReceiver as getPlayerJsReceiver } from './player-js';
import { AudioController } from './services/audio-controller';
import { ImageAnalyzer } from './services/image-analyzer';
import { PlayerController } from './services/player-controller';

const audioController = new AudioController(
  document.querySelector('#sl-audio') as HTMLAudioElement
);
const imageAnalyzer = new ImageAnalyzer(
  document.querySelector('#sl-canvas') as HTMLCanvasElement
);
const playerController = new PlayerController(
  audioController,
  getPlayerJsReceiver()
);

type ViewLoader = Promise<{ default: VueConstructor }> | null;

let viewLoader: ViewLoader = null;

function parseUrl() {
  const log = getLogger(parseUrl);
  const segments = window.location.pathname.split('/');
  const searchParams = new URLSearchParams(window.location.search);
  const settingsSlug = segments[1];
  const query: PlaylistQuery = {};
  let settingsOverrides;

  if (segments[2]) {
    searchParams.set(segments[2], segments[3]);
  } else {
    if (settingsSlug && !settingsSlug.startsWith('_')) {
      query['channel'] = settingsSlug;
    }
  }

  // default vales for reverb and IE11 flags
  let isReverb = config.reverbHostnames.indexOf(window.location.hostname) > -1;
  let isIE11 = !!navigator.userAgent.match(/Trident/);

  searchParams.sort();

  searchParams.forEach((value, key) => {
    if (key.startsWith('_')) {
      switch (key) {
        case '__v':
        case '__view':
          // eslint-disable-next-line default-case
          switch (value.toLocaleLowerCase()) {
            case 'breadboard':
              viewLoader = import(
                './components/players/Breadboard.vue'
              ) as ViewLoader;
              break;
            case 'microcast':
              viewLoader = import(
                './components/players/MicrocastPlayer.vue'
              ) as ViewLoader;
              break;
            case 'linear':
              viewLoader = import(
                './components/players/PlayerV3Linear.vue'
              ) as ViewLoader;
              break;
            case 'radial':
              viewLoader = import(
                './components/players/PlayerV3Radial.vue'
              ) as ViewLoader;
              break;
            case 'legacy':
              viewLoader = import(
                './components/players/PlayerV3Legacy.vue'
              ) as ViewLoader;
              break;
            case 'reverb':
              isReverb = true;
              break;
            case 'ie11':
              isIE11 = true;
              break;
          }
          break;
        case '__s':
        case '__settings':
          try {
            settingsOverrides = JSON.parse(decodeURIComponent(value));
          } catch (e) {
            if (e instanceof Error) {
              log.error('failed to parse settings from URI', e);
            }
          }
          break;
        default:
          log.debug('unknown setting url search params', { key, value });
      }
    } else if (key === 'url' && value === '') {
      query['url'] = document.referrer;
    } else {
      query[key] = value;
    }
  });

  // old reverb doesn't work IE11 right now
  // if (isIE11 && isReverb) {
  //  window.location.href = `https://reverb.spokenlayer.net/publishers/${settingsSlug}`;
  //  return;
  // }

  // reverb and ie11 override a previously set viewLoader
  if (isReverb) {
    viewLoader = import('./components/players/ReverbPlayer.vue') as ViewLoader;
  } else if (isIE11) {
    viewLoader = import('./components/players/IE11Player.vue') as ViewLoader;
  }

  playerController.init(settingsSlug, query, settingsOverrides);
}

parseUrl();

Vue.use(VueMeta);
Vue.directive('balance-text', balanceText);
Vue.config.productionTip = false;

if (!viewLoader) {
  viewLoader =
    window.innerHeight >= 250
      ? (import('./components/players/PlayerV3Linear.vue') as ViewLoader)
      : (import('./components/players/PlayerV3Legacy.vue') as ViewLoader);
}

interface CustomWindow extends Window {
  spokenlayer: {
    player: {
      config: unknown;
      controller: PlayerController;
    };
  };
}

((window as unknown) as CustomWindow).spokenlayer = {
  player: {
    config,
    controller: playerController,
  },
};

const os: string = window.navigator.platform.indexOf('Mac') > -1 ? 'Mac' : '';

viewLoader!.then(m => {
  new Vue({
    render: h => h(m.default),
    provide: () => {
      return {
        controller: playerController,
        imageAnalyzer,
        os,
      };
    },
    errorCaptured(err: Error) {
      playerController.error(err);
    },
  }).$mount('#app');
});
