import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { filter, first } from 'rxjs/operators';
import { getLogger } from './logging';
import { config } from '@/config';
const pick = require('lodash.pick');

export async function fetchJson<R>(
  log: Logger,
  endpoint: string,
  params: Record<string, string | undefined> = {}
): Promise<R & JsonObject> {
  const queryString = Object.keys(params)
    .filter(key => params[key] !== undefined)
    .map(
      key =>
        `${encodeURIComponent(key)}=${encodeURIComponent(params[key] || '')}`
    )
    .join('&');
  const url = `${config.apiUrl}/${endpoint}?${queryString}`;

  log.debug('Fetching JSON...', {
    url,
    endpoint,
    params,
  });

  let response: Response | null = null;
  const ie11 = navigator.userAgent.match(/Trident/);

  try {
    const request: Promise<Response> = !ie11
      ? fetch(url)
      : import(/* webpackChunkName: 'polyfill-fetch' */ 'whatwg-fetch').then(
          module => {
            return module.fetch(url);
          }
        );

    response = await request;
    return (await response.json()) as R & JsonObject;
  } catch (e) {
    if (e instanceof Error) {
      log.error(
        fetchJson,
        e,
        response
          ? {
              ...pick(response, ['ok', 'status', 'statusText', 'url']),
              text: await response.text(),
            }
          : undefined
      );
    }
    throw e;
  }
}

export class SubscriptionManager {
  private readonly log = getLogger(SubscriptionManager);
  private readonly subscriptions: Subscription[] = [];

  watch<T>(o$: Observable<T>, next: (t: T) => void): this {
    this.subscriptions.push(
      o$.subscribe(next, err => {
        this.log.error(this.watch, err);
      })
    );
    return this;
  }

  close(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}

export class AsyncGate {
  readonly locked$ = new BehaviorSubject<boolean>(false);

  lock() {
    this.locked$.next(true);
  }

  unlock() {
    this.locked$.next(false);
  }

  error(e: Error) {
    this.locked$.error(e);
  }

  async untilUnlocked(): Promise<void> {
    await this.locked$
      .pipe(
        filter(locked => !locked),
        first()
      )
      .toPromise();
  }

  isLocked(): boolean {
    return this.locked$.getValue();
  }
}
