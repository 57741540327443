import { getLogger } from '@/core/logging';
import { fetchJson } from '@/core/utils';
const defaultsDeep = require('lodash.defaultsdeep');

export class SettingsLoader {
  readonly defaultSettings: WebPlayerSettings = {
    autoplay: false,
    controls: {
      replayButtonAfter: 10,
    },
    design: {
      theme: 'light',
    },
  };

  private readonly log = getLogger(SettingsLoader);

  async load(slug: string): Promise<WebPlayerSettings> {
    if (!slug.startsWith('_')) {
      const r = await fetchJson<ResponseEnvelope<Partial<WebPlayerSettings>>>(
        this.log,
        'settings',
        { publisher: slug }
      );

      if (r.success) {
        return defaultsDeep(r.result, this.defaultSettings);
      } else {
        this.log.warn('failed to load playlist', r.error);
        return this.defaultSettings;
      }
    } else {
      return this.defaultSettings;
    }
  }
}
