import { Observable, Subject, combineLatest, merge, timer } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { AdsController } from './ads-controller';
import { AudioController } from './audio-controller';
import { ContentManager, ContentState } from './content-manager';
import { SettingsLoader } from './settings-loader';
import { StateManager } from '@/core/state-manager';
import { ClickTracker, Introspector } from '@/core/introspector';
import { getLogger } from '@/core/logging';
import { config } from '@/config';
const propMerge = require('lodash.merge');

export interface PlaybackState {
  readonly playing?: boolean;
  readonly suspended?: boolean;
  readonly loading?: boolean;
  readonly muted?: boolean;
  readonly volume?: number; // volume from 0-1 inclusive
  readonly buffered?: number; // portion of media loaded from 0-1 inclusive
  readonly error?: Error;
  readonly adDuration?: number;
}

export interface PlayerState extends PlaybackState, ContentState {
  settings?: WebPlayerSettings;
}

export class PlayerController extends StateManager<PlayerState> {
  static readonly ACTIVE_KEY = 'activePlayerId';

  readonly id = `${Math.floor(Math.random() * 1000)}`;
  /**
   * Observable of the current audio position in seconds, rounded to the nearest second
   */
  readonly currentTime$: Observable<number>;

  /**
   * Observable of the remaining time on a linear ad
   */
  readonly remainingAdTime$: Observable<number>;

  get clickTracker(): ClickTracker {
    return this.introspector;
  }

  get currentTime(): number {
    return this.audio ? Math.round(this.audio.currentTime) || 0 : 0;
  }

  get duration(): number {
    return this.content.state.audioDuration || 0;
  }

  get remainingAdTime(): number {
    return Math.round(this.ads.getRemainingTime());
  }

  private readonly ads: AdsController;
  private readonly introspector = new Introspector();
  private readonly autoTick$ = timer(0, config.timerTickMs);
  private readonly manualTick$ = new Subject<void>();

  private sessionStart = true;
  private pendingAdRequest?: string;

  constructor(
    private readonly audio: AudioController,
    private readonly receiver: playerjs.Receiver,
    private readonly content = new ContentManager(),
    private readonly settingsLoader = new SettingsLoader()
  ) {
    super(
      {
        ...content.state,
        volume: 1,
      },
      getLogger(PlayerController)
    );
    this.ads = new AdsController(this);
    this.audio.setCallbacks({
      start: () => {
        this.receiver.emit('spokenlayer.audioStart');
        this.introspector.trackStoryEvent('start', this.content.state.story);
        this.update({ audioDuration: this.audio.duration });
      },
      end: () => {
        this.receiver.emit('ended');
        this.introspector.trackStoryEvent('end', this.content.state.story);
        this.ads.contentComplete();
        this.next();
      },
      bufferProgress: buffered => {
        this.update({ buffered });
        this.receiver.emit('progress', { percent: buffered * 100 });
      },
    });
    this.currentTime$ = merge(this.autoTick$, this.manualTick$).pipe(
      map(() => this.currentTime),
      distinctUntilChanged()
    );
    this.remainingAdTime$ = merge(this.autoTick$, this.manualTick$).pipe(
      map(() => this.remainingAdTime),
      distinctUntilChanged()
    );
    combineLatest([
      this.ads.loading$,
      this.audio.loading$,
      this.content.loading$,
    ])
      .pipe(
        map(([l1, l2, l3]) => l1 || l2 || l3),
        distinctUntilChanged()
      )
      .subscribe(loading => {
        this.update({ loading });
      });
    content.updates$.subscribe(u => this.update(u));

    this.receiver.on('play', () => this.play());
    this.receiver.on('pause', () => this.pause());
    this.receiver.on('getPaused', cb => cb(!this.state.playing));
    this.receiver.on('mute', () => this.mute());
    this.receiver.on('unmute', () => this.unmute());
    this.receiver.on('getMuted', cb => cb(!!this.state.muted));
    this.receiver.on('setVolume', v => this.setVolume(v));
    this.receiver.on('getVolume', cb => cb(this.state.volume || 0));
    this.receiver.on('getDuration', cb => cb(this.state.audioDuration || 0));
    this.receiver.on('setCurrentTime', time => (this.audio.currentTime = time));
    this.receiver.on('getCurrentTime', cb => cb(this.audio.currentTime));
    this.receiver.on('setLoop', loop => (this.audio.loop = !!loop));
    this.receiver.on('getLoop', cb => cb(this.audio.loop));
    this.currentTime$.subscribe(seconds => {
      this.receiver.emit('timeupdate', {
        seconds,
        duration: this.state.audioDuration,
      });

      if (seconds && (seconds === 5 || seconds % 10 === 0)) {
        this.introspector.trackStoryEvent(
          'heartbeat',
          this.content.state.story,
          {
            secondsListened: seconds,
          }
        );
      }

      if (
        this.state.audioDuration &&
        seconds === this.state.audioDuration - 5
      ) {
        this.introspector.trackStoryEvent(
          'heartbeat',
          this.content.state.story,
          {
            secondsLeft: 5,
          }
        );
      }
    });
    this.detectActivePlayer();
  }

  /**
   * load the player settings and set the playlist query parameters
   *
   * @param settingsSlug settings slug for web-player settings API endpoint
   * @param query playlist query parameters for web-player playlist API endpoint
   * @param settingsOverrides playlist query parameters for web-player playlist API endpoint
   */
  async init(
    settingsSlug: string,
    query: PlaylistQuery,
    settingsOverrides?: object
  ) {
    if (!settingsSlug) {
      this.error(new Error('No player/distributor ID provided'));
    } else {
      await this.loadSettings(settingsSlug, settingsOverrides);
      this.setPlaylist(query);
      this.introspector.trackSessionEvent('ready');
    }
  }

  /**
   * enables ads and provides a DOM container in which to display video or accompanying creatives
   *
   * @param adContainer the container for ad visuals
   */
  async initAds(adContainer: HTMLElement) {
    const userAgent = window.navigator.userAgent;
    const ios = /iPhone|iPod|iPad/.test(userAgent);
    if (!ios) {
      this.ads.init(adContainer);
    }
  }

  async play() {
    this.update({ playing: true });
    this.setActivePlayer();
    this.audio.pause();

    if (this.sessionStart) {
      this.receiver.emit('spokenlayer.sessionStart');
      this.introspector.trackSessionEvent('start');
      this.sessionStart = false;
    }

    if (this.pendingAdRequest) {
      const requestUrl = this.pendingAdRequest;
      this.log.debug('loading ads...', { url: this.pendingAdRequest });
      this.pendingAdRequest = undefined;
      await this.ads.load(requestUrl);
      this.log.debug('finished loading ads.');
    }

    if (this.state.suspended) {
      this.ads.resume();
    } else {
      if (!this.ads.start()) {
        try {
          await this.audio.play();
          this.receiver.emit('play');
          this.introspector.trackStoryEvent('play', this.content.state.story);
        } catch (err) {
          this.update({ playing: false });
          this.audio.pause();
          this.receiver.emit('pause');
        }
      }
    }
  }

  pause() {
    this.update({ playing: false });

    if (this.state.suspended) {
      this.ads.pause();
    } else {
      this.audio.pause();
      this.receiver.emit('pause');
      this.introspector.trackStoryEvent('pause', this.content.state.story);
    }
  }

  async next() {
    this.introspector.trackStoryEvent('next', this.content.state.story);
    await this.loadSlot(this.content.next());
    this.manualTick$.next();
  }

  async previous() {
    this.introspector.trackStoryEvent('previous', this.content.state.story);
    await this.loadSlot(this.content.previous());
    this.manualTick$.next();
  }

  async goto(i: number) {
    await this.loadSlot(this.content.goto(i));
    this.play();
    this.manualTick$.next();
  }

  mute() {
    this.update({ muted: true });
    this.audio.mute();
  }

  unmute() {
    this.update({ muted: false });
    this.audio.unmute();
  }

  /**
   * @param n the speed multiplier, with 1 being normal playback speed
   */
  setPlaybackSpeed(n: number) {
    this.audio.rate = n;
    this.introspector.trackStoryEvent(
      'setPlaybackSpeed',
      this.content.state.story,
      { playbackSpeed: n }
    );
  }

  /**
   * @param volume between 0 and 1 inclusive
   */
  setVolume(volume: number) {
    this.update({ volume: this.audio.volume });
    this.audio.volume = volume;
  }

  /**
   * move playback to the given time
   *
   * @param n the time in seconds
   */
  seek(n: number) {
    this.audio.seek(n);
    this.manualTick$.next();
    this.trackSeek();
  }

  /**
   * move playback to a position as a proportion of the total duration
   *
   * @param p a value between 0 and 1 inclusive
   */
  seekProportionately(p: number) {
    this.audio.seekProportionately(p);
    this.manualTick$.next();
    this.trackSeek();
  }

  /**
   * move playback relative to the current time
   *
   * @param seconds the time in seconds to move (can be positive or negative)
   */
  seekRelative(seconds: number) {
    this.audio.seekRelative(seconds);
    this.manualTick$.next();
    this.trackSeek();
  }

  /**
   * put the player in a suspended state for ads
   */
  suspend() {
    this.introspector.trackAdEvent('start', this.state.story);
    this.update({
      suspended: true,
      adDuration: this.ads.getRemainingTime(),
    });

    if (this.state.playing) {
      this.audio.pause();
    }
  }

  /**
   * resume playback after ads
   */
  async resume() {
    this.update({
      suspended: false,
      adDuration: 0,
    });
    this.manualTick$.next();
    await stall(config.msStallAfterAd);

    if (this.state.playing) {
      await this.audio.play();
    }
  }

  /**
   * pauses ad playback, or resumes playback if paused
   */
  toggleAdsPlaying() {
    this.ads.toggle();
  }

  /**
   * set an error state for the player
   *
   * @param error the Error that was encountered
   */
  error(error: Error) {
    this.update({ error });
    this.receiver.emit('error', error);
  }

  private async loadSettings(slug: string, settingsOverrides: object = {}) {
    const settings = propMerge(
      await this.settingsLoader.load(slug),
      settingsOverrides
    );

    this.log.inspect(this.loadSettings, settings);
    this.introspector.startTracking(slug);
    this.update({
      settings,
    });

    if (settings.autoplay) {
      this.update({ playing: true });
    }
  }

  private async setPlaylist(query: PlaylistQuery) {
    this.content.setPlaylist(query);
    await this.loadSlot(this.content.first());

    if (this.sessionStart) {
      this.receiver.ready();
    }
  }

  private async loadSlot(promisedSlot: Promise<PlaylistSlot | null>) {
    try {
      const slot = await promisedSlot;

      if (slot) {
        this.audio.setSource(slot.story.formats.mp3.src, this.state.playing);

        if (slot.ads) {
          this.pendingAdRequest = slot.ads.requestUrl;
        }

        if (this.state.playing) {
          await this.play();
        }
      }
    } catch (error) {
      if (error instanceof Error) {
        this.update({ error });
      }
    }
  }

  private trackSeek() {
    this.receiver.emit('seeked');

    if (this.audio.currentTime === 0) {
      this.introspector.trackStoryEvent('restart', this.content.state.story);
    } else {
      this.introspector.trackStoryEvent('rewind', this.content.state.story, {
        secondsListened: this.audio.currentTime,
      });
    }
  }

  /**
   * listen for changes to the active player ID in local storage and pause this player if another player is active
   */
  private detectActivePlayer() {
    window.addEventListener('storage', (e: StorageEvent) => {
      if (e.key === PlayerController.ACTIVE_KEY) {
        if (this.id !== e.newValue && this.state.playing) {
          this.pause();
        }
      }
    });
  }

  /**
   * set the active player ID in local storage to this player ID
   */
  private setActivePlayer() {
    try {
      localStorage.setItem(PlayerController.ACTIVE_KEY, this.id);
    } catch (e) {
      if (e instanceof Error) {
        this.log.warn(this.setActivePlayer, e);
      }
    }
  }
}

/**
 * Returns a promise that resolves after a given amount of time
 *
 * @param timeoutMs the time to stall in milliseconds
 */
function stall(timeoutMs: number): Promise<void> {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve();
    }, timeoutMs);
  });
}
