import { config } from '@/config';
import { getLogger } from '@/core/logging';
import 'proxy-polyfill/proxy.min.js';

const log = getLogger('playerjs.Receiver');

type CustomPlayerJs = typeof playerjs & {
  EVENTS: {
    SL_SESSION_START: string;
    SL_AUDIO_START: string;
  };
};

const EVENTS = (playerjs as CustomPlayerJs).EVENTS;
EVENTS.SL_SESSION_START = 'spokenlayer.sessionStart';
EVENTS.SL_AUDIO_START = 'spokenlayer.audioStart';

export function getReceiver() {
  const receiver = config.logging.logPlayerJsEvents
    ? new Proxy(new playerjs.Receiver(), {
        get: (o: playerjs.Receiver, prop: keyof playerjs.Receiver) => {
          if (prop === 'emit') {
            return (event: string, ...args: unknown[]) => {
              log.trace('emit >>', { event, args });
              o.emit.call(o, event, ...args);
            };
          }
          return o[prop];
        },
      })
    : new playerjs.Receiver();

  window.addEventListener('message', (event: MessageEvent) => {
    log.inspect(getReceiver, event);

    if (event.data === 'spokenlayer.reemitReadyEvent') {
      if (receiver.isReady) {
        receiver.ready();
      }
    }
  });

  return receiver;
}
