import { config } from '@/config';
import { getLogger } from '@/core/logging';
import { AsyncGate } from '@/core/utils';

export interface ContentPlayback {
  currentTime: number;
  duration: number;
  suspend(): void;
  resume(): void;
}

export class AdsController {
  get loading$() {
    return this.adRequestGate.locked$;
  }

  private readonly log = getLogger(AdsController);
  private adRequestGate = new AsyncGate();
  private adsManager: google.ima.AdsManager | null = null;
  private adsLoader: google.ima.AdsLoader | null = null;
  private adContainer!: HTMLElement;
  private adDisplayContainer!: google.ima.AdDisplayContainer;
  private linearAdPlaying = false;
  private adsManagerStarted = false;

  constructor(private content: ContentPlayback) {}

  async init(adContainer: HTMLElement) {
    try {
      this.adContainer = adContainer;
      this.adDisplayContainer = new google.ima.AdDisplayContainer(adContainer);
      // Initialize the container. Must be done via a user action on mobile devices.
      this.adDisplayContainer.initialize();

      this.adsLoader = new google.ima.AdsLoader(this.adDisplayContainer);
      this.adsLoader.addEventListener(
        google.ima.AdsManagerLoadedEvent.Type.ADS_MANAGER_LOADED,
        e => this.onAdsManagerLoaded(e),
        false
      );
      this.adsLoader.addEventListener(
        google.ima.AdErrorEvent.Type.AD_ERROR,
        e => this.handleAdError(e),
        false
      );
    } catch (e) {
      if (e instanceof Error) {
        this.log.error(this.init, e);
      }
    }
  }

  getRemainingTime(): number {
    return this.adsManager && this.adsManager.getRemainingTime() > 0
      ? this.adsManager.getRemainingTime()
      : 0;
  }

  async load(adTagUrl: string): Promise<void> {
    if (this.adsLoader) {
      this.unload();

      if (google.ima && !config.disableAds) {
        try {
          this.adRequestGate.lock();
          this.adsLoader.requestAds(this.buildAdsRequest(adTagUrl));
        } catch (e) {
          if (e instanceof Error) {
            this.log.error(this.load, e);
          }
          this.unload();
        }
      }

      await this.adRequestGate.untilUnlocked();
    }
  }

  resume() {
    if (this.adsManager) {
      this.adsManager.resume();
      this.linearAdPlaying = true;
    }
  }

  pause() {
    if (this.adsManager) {
      this.adsManager.pause();
      this.linearAdPlaying = false;
    }
  }

  toggle() {
    if (this.linearAdPlaying) {
      this.pause();
    } else {
      this.resume();
    }
  }

  contentComplete() {
    if (this.adsLoader) {
      this.adsLoader.contentComplete();
    }
  }

  start(): boolean {
    try {
      if (this.adsManager && !this.adsManagerStarted) {
        // Initialize the ads manager. Ad rules playlist will start at this time.
        this.adsManager.init(640, 360, google.ima.ViewMode.NORMAL);
        // Call play to start showing the ad. Single video and overlay ads will
        // start at this time; the call will be ignored for ad rules.
        this.adsManager.start();
        this.adsManagerStarted = true;
        return true;
      }
    } catch (e) {
      if (e instanceof Error) {
        this.log.error(this.start, e);
      }
      this.unload();
    }

    return false;
  }

  private unload() {
    if (this.adsManager) {
      this.adsManager.destroy();
      this.adsManager = null;
    }

    this.adsManagerStarted = false;
    this.adRequestGate.unlock();
  }

  private buildAdsRequest(adTagUrl: string) {
    const adsRequest = new google.ima.AdsRequest();
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const d = this.adContainer.parentElement!.getBoundingClientRect();

    adsRequest.adTagUrl = adTagUrl;
    // Specify the linear and nonlinear slot sizes. This helps the SDK to
    // select the correct creative if multiple are returned.
    adsRequest.linearAdSlotWidth = d.width;
    adsRequest.linearAdSlotHeight = d.height;
    adsRequest.nonLinearAdSlotWidth = d.width;
    adsRequest.nonLinearAdSlotHeight = d.height;
    this.log.inspect(this.buildAdsRequest, adsRequest);
    return adsRequest;
  }

  private onAdsManagerLoaded(
    adsManagerLoadedEvent: google.ima.AdsManagerLoadedEvent
  ) {
    this.log.inspect(this.onAdsManagerLoaded, adsManagerLoadedEvent);

    try {
      // Get the ads manager.
      const adsRenderingSettings = new google.ima.AdsRenderingSettings();
      const adsManager = adsManagerLoadedEvent.getAdsManager(
        this.content,
        adsRenderingSettings
      );

      adsManager.addEventListener(google.ima.AdErrorEvent.Type.AD_ERROR, e =>
        this.handleAdError(e)
      );
      [
        google.ima.AdEvent.Type.LOADED,
        google.ima.AdEvent.Type.CONTENT_PAUSE_REQUESTED,
        google.ima.AdEvent.Type.STARTED,
        google.ima.AdEvent.Type.ALL_ADS_COMPLETED,
        google.ima.AdEvent.Type.CONTENT_RESUME_REQUESTED,
      ].forEach(t => {
        adsManager.addEventListener(t, e => this.handleAdEvent(e));
      });

      this.adsManager = adsManager;
    } catch (e) {
      if (e instanceof Error) {
        this.log.error(this.onAdsManagerLoaded, e);
      }
      this.unload();
    }

    this.adRequestGate.unlock();
  }

  private handleAdEvent(adEvent: google.ima.AdEvent) {
    this.log.inspect(this.handleAdEvent, adEvent);

    // Retrieve the ad from the event. Some events (e.g. ALL_ADS_COMPLETED)
    // don't have ad object associated.

    switch (adEvent.type) {
      case google.ima.AdEvent.Type.LOADED:
        // This is the first event sent for an ad - it is possible to
        // determine whether the ad is a video ad or an overlay.
        if (!adEvent.getAd()?.isLinear()) {
          // Position AdDisplayContainer correctly for overlay.
          // Use ad.width and ad.height.
          this.content.resume();
        }
        break;
      case google.ima.AdEvent.Type.CONTENT_PAUSE_REQUESTED:
        this.content.suspend();
        this.linearAdPlaying = true;
        break;
      case google.ima.AdEvent.Type.STARTED:
        // This event indicates the ad has started - the video player
        // can adjust the UI, for example display a pause button and
        // remaining time.
        break;
      case google.ima.AdEvent.Type.ALL_ADS_COMPLETED:
        // This event indicates the ad has finished - the video player
        // can perform appropriate UI actions, such as removing the timer for
        // remaining time detection.
        break;
      case google.ima.AdEvent.Type.CONTENT_RESUME_REQUESTED:
        this.linearAdPlaying = false;
        this.content.resume();
        break;
      default:
        break;
    }
  }

  private handleAdError(adErrorEvent: google.ima.AdErrorEvent) {
    // Handle the error logging.
    this.log.error(this.handleAdError, adErrorEvent.getError());
    this.unload();
    this.content.resume();
  }
}
