import { Observable } from 'rxjs';
import { PlaylistLoader } from './playlist-loader';
import { getLogger } from '@/core/logging';
import { StateManager } from '@/core/state-manager';

export interface ContentState {
  readonly story?: Story;
  readonly channelName?: string;
  readonly publisher?: Publisher;
  readonly audioDuration?: number;
  readonly hasPrevious?: boolean;
  readonly hasNext?: boolean;
  readonly shareUrl?: string;
  readonly trackList?: PlaylistTrackInfo[];
}

export class ContentManager extends StateManager<ContentState> {
  get loading$(): Observable<boolean> {
    return this.loader.loading$;
  }

  private slotIndex = 0;

  constructor(private readonly loader: PlaylistLoader = new PlaylistLoader()) {
    super({}, getLogger(ContentManager));
  }

  async setPlaylist(playlistQuery: PlaylistQuery) {
    this.loader.query = playlistQuery;
  }

  first(): Promise<PlaylistSlot | null> {
    return this.loadSlot(0);
  }

  next(): Promise<PlaylistSlot | null> {
    return this.loadSlot(this.slotIndex + 1);
  }

  previous(): Promise<PlaylistSlot | null> {
    return this.loadSlot(this.slotIndex - 1);
  }

  goto(i: number): Promise<PlaylistSlot | null> {
    return this.loadSlot(i);
  }

  private async loadSlot(i: number): Promise<PlaylistSlot | null> {
    const slot = await this.loader.loadSlot(i);

    this.slotIndex = i;

    if (slot) {
      const publisher = this.loader.getPublisher(slot.story.publisherId);
      const trackList = this.loader.trackList;

      if (
        !this.state.trackList ||
        trackList.length !== this.state.trackList.length
      ) {
        this.update({ trackList });
      }

      if (!this.state.publisher || publisher.id !== this.state.publisher.id) {
        this.update({ publisher });
      }

      this.update({
        story: slot.story,
        channelName: publisher.channelName || publisher.name,
        audioDuration: Math.round(slot.story.formats.mp3.duration),
        hasPrevious: this.slotIndex > 0,
        hasNext: await this.loader.hasNext(this.slotIndex),
        shareUrl: slot.shareUrl,
      });
      return slot;
    }

    return null;
  }
}
