import { getLogger } from './logging';
import { config } from '@/config';

declare const dataLayer: object[];

export interface ClickTracker {
  setEventCategory(category: 'player' | 'reverb'): void;

  trackClickSubscribe(
    subscribeType:
      | 'alexa-skill'
      | 'alexa-briefing'
      | 'google-action'
      | 'google-briefing'
      | 'itunes'
      | 'spotify'
      | 'google'
      | 'iheartradio'
      | 'overcast',
    story?: Story
  ): void;

  trackClickShare(
    shareType: 'sms' | 'email' | 'facebook' | 'twitter',
    story?: Story
  ): void;
}

export class Introspector implements ClickTracker {
  private readonly log = getLogger(Introspector);
  private distributerId = '';
  private trackerData = {
    trackerNamespace: 'web-player',
    appId: 'default',
    platform: 'web',
    userId: 'test_user_id',
    language: 'en',
  };
  private commonData = {};
  private googleEventCategory: 'player' | 'reverb' = 'player';

  constructor() {
    this.loadGtm();
  }

  startTracking(distributerId: string): void {
    this.distributerId = distributerId;
  }

  setEventCategory(category: 'player' | 'reverb') {
    this.googleEventCategory = category;
  }

  trackAdEvent(eventName: 'start', story?: Story) {
    if (eventName === 'start') {
      this.trackCustomGtmEvent('adStart', story);
    }
  }

  trackSessionEvent(
    eventName: 'ready' | 'start',
    data: { setlistId?: string; voiceInput?: string } = {}
  ) {
    if (eventName === 'ready') {
    }

    if (eventName === 'start') {
      this.trackCustomGtmEvent('sessionStart');
    }
  }

  trackStoryEvent(
    eventName:
      | 'start'
      | 'stop'
      | 'pause'
      | 'play'
      | 'end'
      | 'complete'
      | 'next'
      | 'previous'
      | 'restart'
      | 'download'
      | 'share'
      | 'subscribe'
      | 'heartbeat'
      | 'setPlaybackSpeed'
      | 'rewind',
    story: Story | undefined,
    data: {
      voiceInputText?: string;
      setlistId?: string;
      autoplay?: boolean;
      storyLength?: number;
      secondsListened?: number;
      secondsLeft?: number;
      playbackSpeed?: number;
      shareType?: 'twitter' | 'email' | 'fb' | 'sms';
      advertisementId?: string;
      sharingService?: string;
      subscriptionService?: string;
    } = {}
  ) {
    if (eventName === 'start' && story) {
      this.trackCustomGtmEvent('audioStart', story);
    }
  }

  trackClickSubscribe(
    subscribeType:
      | 'alexa-skill'
      | 'alexa-briefing'
      | 'google-action'
      | 'google-briefing'
      | 'itunes'
      | 'spotify'
      | 'google'
      | 'iheartradio'
      | 'overcast',
    story: Story
  ) {
    this.trackCustomGtmEvent('clickSubscribe', story, subscribeType);
  }

  trackClickShare(
    shareType: 'sms' | 'email' | 'facebook' | 'twitter',
    story?: Story
  ) {
    this.trackCustomGtmEvent('clickShare', story, shareType);
  }

  private loadGtm() {
    const w = (window as unknown) as Window & { dataLayer: object[] };
    w.dataLayer = w.dataLayer || [];
    w.dataLayer.push({
      'gtm.start': new Date().getTime(),
      event: 'gtm.js',
    });
    const f = document.getElementsByTagName('script')[0];
    const j: HTMLScriptElement = document.createElement('script');
    const queryParams: Record<string, string | number | boolean> = {
      id: config.gtm.id,
      ...config.gtm.queryParams,
    };

    const queryString = Object.keys(queryParams)
      .map(
        key =>
          `${encodeURIComponent(key)}=${encodeURIComponent(
            queryParams[key] || ''
          )}`
      )
      .join('&');

    j.defer = true;
    j.src = `https://www.googletagmanager.com/gtm.js?${queryString}`;
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    f.parentNode!.insertBefore(j, f);
  }

  private trackCustomGtmEvent(
    action: string,
    story?: Story,
    labelSuffix?: string
  ) {
    const _labelSuffix = labelSuffix ? `-${labelSuffix}` : '';
    const storyLabel = story ? story.id : '?';
    const label = `${this.distributerId}-${storyLabel}${_labelSuffix}`;

    dataLayer.push({
      event: 'spokenlayerEvent',
      spokenlayerEventCategory: this.googleEventCategory,
      spokenlayerEventAction: action,
      spokenlayerEventLabel: label,
    });
  }
}
