import { Observable, Subject } from 'rxjs';

interface ServiceState {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

/**
 * Boilerplate state management for services that expose and update a public state
 */
export abstract class StateManager<S extends ServiceState> {
  private readonly _updates$ = new Subject<Partial<S>>();

  get state(): Readonly<S> {
    return this._state;
  }

  constructor(private _state: S, protected log: Logger) {}

  get updates$(): Observable<Partial<S>> {
    return this._updates$;
  }

  protected update(updates: Partial<S>) {
    this.log.inspect(this.update, updates);
    this._state = {
      ...this._state,
      ...updates,
    };
    this._updates$.next({ ...updates });
  }
}
